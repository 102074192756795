// src/components/Navigation.js
import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/2f624a47-37d1-4b2f-a03b-f5b78d90db3f.jpg'

function Navigation() {
    return (
        <nav className="bg-gray-800 py-4 px-6 flex justify-between sticky top-0" style={{zIndex: "10000"}}>
            <div className="flex items-center">
                {/* <Link to="/">
                    <img
                        className="h-8 w-8 mr-2 rounded-full"
                        src={logo}
                        alt="Me in a hat"
                    />
                </Link> */}
            </div>
            <div className="text-center flex-grow mt-1">
                <h1 className="text-white font-bold text-l md:text-2xl"><a href='/'>Good family owned contractors.</a></h1>
            </div>
            {/* <ul className="flex items-center">
                <li>
                    <Link to="/" className="text-gray-300 hover:bg-gray-700 py-2 px-3 rounded-md text-center">
                        About
                    </Link>
                </li>
                <li>
                    <Link to="/work" className="text-gray-300 hover:bg-gray-700 py-2 px-3 rounded-md text-center">
                        Work
                    </Link>
                </li>
                <li>
                    <Link to="/contact" className="text-gray-300 hover:bg-gray-700 py-2 px-3 rounded-md text-center">
                        Contact
                    </Link>
                </li> 
            </ul>*/}
        </nav>
    );
}

export default Navigation;
