// src/App.js
import React from 'react';

import Navigation from './components/Navigation';
import PicturesReel from './components/PicturesReel';
import CreditCardForm from './components/CardForm';

function App() {
  return ( 
    <div className='App'>
      <Navigation />
      <PicturesReel></PicturesReel>
      <CreditCardForm/>
    </div>
  );
}

export default App;