import React from 'react';

import picture1 from '../assets/kinch.jpeg'
import picture2 from '../assets/jack.jpeg'
import picture3 from '../assets/eli.jpeg'
import picture4 from '../assets/good_deals.jpeg'
import picture5 from '../assets/imperial.jpeg'
import picture6 from '../assets/ryan.jpeg'
import picture7 from '../assets/johnny.jpeg'
import picture8 from '../assets/bob.jpeg'

const PicturesReel = () => {
    const images = [        
        {
            src: picture8,
            caption: 'William Davidson. CEO.',
        },
        {
            src: picture1,
            caption: 'Senyor Ginch, smokes many fags good worker.',
        },
        {
            src: picture2,
            caption: 'Hack Bierce, the best boy.',
        },
        {
            src: picture3,
            caption: 'Elly, weres protective clothing.',
        },
        {
            src: picture4,
            caption: 'Hank and Jeremy, job well done at parish grave yard.',
        },
        {
            src: picture5,
            caption: 'Bakugan Don Thong II. very experienced in industry.',
        },
        {
            src: picture6,
            caption: 'Bob. good man.',
        },
        {
            src: picture7,
            caption: 'Qwan, diverse workforce.',
        },
    ];

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-12">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold text-center text-gray-900 sm:text-4xl pb-5">
                        Why work with us? Here is why.
                    </h1>
                    <div className="flex flex-wrap">
                        {images.map((image, index) => (
                            <div className="flex flex-col items-center flex-grow m-2" key={index}>
                                <img src={image.src} alt={`Image ${index + 1}`} className="w-1/2" />
                                <p className="text-lg mt-2">{image.caption}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PicturesReel;
