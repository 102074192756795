import { useState } from 'react';

const CardForm = () => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');

    function handleCardNumberChange(event) {
        setCardNumber(event.target.value);
    }

    function handleExpiryDateChange(event) {
        setExpiryDate(event.target.value);
    }

    function handleCvvChange(event) {
        setCvv(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        // TODO: Handle submission of credit card details
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pb-12">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold text-center text-gray-900 sm:text-4xl pb-5">
                        Contact Form
                    </h1>
                    <form
                        onSubmit={handleSubmit}
                        className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md border border-gray-200"
                    >
                        <label className="block mb-4">
                            Card number:
                            <input
                                type="text"
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                className="border border-gray-400 rounded-md p-2 w-full"
                            />
                        </label>
                        <label className="block mb-4">
                            Expiry date:
                            <input
                                type="text"
                                value={expiryDate}
                                onChange={handleExpiryDateChange}
                                className="border border-gray-400 rounded-md p-2 w-full"
                            />
                        </label>
                        <label className="block mb-4">
                            CVV:
                            <input
                                type="text"
                                value={cvv}
                                onChange={handleCvvChange}
                                className="border border-gray-400 rounded-md p-2 w-full"
                            />
                        </label>
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CardForm;
